
const Constants = {
  BEGIN_SEARCH_NAME: 'BEGIN_SEARCH_NAME',
  FINISHED_SEARCH: 'FINISHED_SEARCH',
  LOAD_USER_DATA: 'LOAD_USER_DATA',
  ERROR_LOADING_DATA: 'ERROR_LOADING_DATA',
  NEXT_PAGE: 'NEXT_PAGE',
  LAST_PAGE: 'LAST_PAGE',
  JUMP_TO_PAGE: 'JUMP_TO_PAGE',
}

export default Constants;